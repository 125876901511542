import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';
import "@/assets/style/base.less"
import "@/assets/style/table.less"
import Print from 'print-js'

const app = createApp(App);
import vue3SeamlessScroll from "vue3-seamless-scroll";
// app.component("vue3SeamlessScroll", vue3SeamlessScroll);

app.use(ArcoVue);
app.use(ArcoVueIcon);
// @ts-ignore
app.use(vue3SeamlessScroll)
app.use(router)
app.mount('#app')

