import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "layout",
    component: () => import(/* webpackChunkName: "about" */ '../views/layout.vue'),
    redirect: "/HomeView",
    children: [
      {
        path: '/HomeView',
        name: 'home',
        meta: {
          keepLive: true
        },
        component: HomeView
      },
      // 智慧党建
      {
        path: '/PartyBranch',
        name: 'PartyBranch',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyBranch.vue')
      },
      {
        path: '/FirstTopic',
        name: 'FirstTopic',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/FirstTopic.vue')
      },
      {
        path: '/FirstTopicDetail',
        name: 'FirstTopicDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/FirstTopicDetail.vue')
      },
      {
        path: '/Integrity',
        name: 'Integrity',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/Integrity.vue')
      },
      {
        path: '/IntegrityDetail',
        name: 'IntegrityDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/IntegrityDetail.vue')
      },
      {
        path: '/OrganizePioneerRule',
        name: 'OrganizePioneerRule',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/OrganizePioneerRule.vue')
      },
      {
        path: '/OrganizePioneer',
        name: 'OrganizePioneer',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/OrganizePioneer.vue')
      },
      {
        path: '/OrganizePioneerDetail',
        name: 'OrganizePioneerDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/OrganizePioneerDetail.vue')
      },
      {
        path: '/PartyPioneerRule',
        name: 'PartyPioneerRule',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyPioneerRule.vue')
      },
      {
        path: '/PartyPioneer',
        name: 'PartyPioneer',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyPioneer.vue')
      },
      {
        path: '/PartyPioneerDetail',
        name: 'PartyPioneerDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyPioneerDetail.vue')
      },
      {
        path: '/PartyPublish',
        name: 'PartyPublish',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyPublish.vue')
      },
      {
        path: '/PartyPublishDetail',
        name: 'PartyPublishDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/party/PartyPublishDetail.vue')
      },
      // 新时代文明
      {
        path: '/Volunteer',
        name: 'Volunteer',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/Volunteer.vue')
      },
      {
        path: '/SportsActivities',
        name: 'SportsActivities',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/SportsActivities.vue')
      },
      {
        path: '/ServiceItems',
        name: 'ServiceItems',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/ServiceItems.vue')
      },
      {
        path: '/CommunityService',
        name: 'CommunityService',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/CommunityService.vue')
      },
      {
        path: '/DigitalReading',
        name: 'DigitalReading',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/DigitalReading.vue')
      },
      {
        path: '/DigitalReadingDetail',
        name: 'DigitalReadingDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/DigitalReadingDetail.vue')
      },
      {
        path: '/CommunityPublish',
        name: 'CommunityPublish',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/CommunityPublish.vue')
      },
      {
        path: '/CommunityPublishDetail',
        name: 'CommunityPublishDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/CommunityPublishDetail.vue')
      },
      {
        path: '/VolunteerActiveList',
        name: 'VolunteerActiveList',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/VolunteerActiveList.vue')
      },
      {
        path: '/VolunteerActiveDetail',
        name: 'VolunteerActiveDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/newera/VolunteerActiveDetail.vue')
      },
      // 新农村
      {
        path: '/LifeInsurance',
        name: 'LifeInsurance',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/LifeInsurance.vue')
      },
      {
        path: '/Disabled',
        name: 'Disabled',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/Disabled.vue')
      },
      {
        path: '/OldAgeGold',
        name: 'OldAgeGold',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/OldAgeGold.vue')
      },
      {
        path: '/TroubledChildren',
        name: 'TroubledChildren',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/TroubledChildren.vue')
      },
      {
        path: '/FiveGuarantees',
        name: 'FiveGuarantees',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/FiveGuarantees.vue')
      },
      {
        path: '/FuneralAllowance',
        name: 'FuneralAllowance',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/FuneralAllowance.vue')
      },
      {
        path: '/BasicPension',
        name: 'BasicPension',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/BasicPension.vue')
      },
      {
        path: '/DeathLogOff',
        name: 'DeathLogOff',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/DeathLogOff.vue')
      },
      {
        path: '/ClearAccount',
        name: 'ClearAccount',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/ClearAccount.vue')
      },
      {
        path: '/NursingInsurance',
        name: 'NursingInsurance',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/NursingInsurance.vue')
      },
      {
        path: '/IntegralRule',
        name: 'IntegralRule',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/IntegralRule.vue')
      },
      {
        path: '/pointSyatem',
        name: 'pointSyatem',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/village/pointSyatem.vue')
      },
      // 综合治理
      {
        path: '/GridInformation',
        name: 'GridInformation',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/GridInformation.vue')
      },
      {
        path: '/GridPublish',
        name: 'GridPublish',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/GridPublish.vue')
      },
      {
        path: '/GridPublishDetail',
        name: 'GridPublishDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/GridPublishDetail.vue')
      },
      {
        path: '/Security',
        name: 'Security',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/Security.vue')
      },
      {
        path: '/Mediation',
        name: 'Mediation',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/Mediation.vue')
      },
      {
        path: '/LawPublish',
        name: 'LawPublish',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/LawPublish.vue')
      },
      {
        path: '/LawPublishDetail',
        name: 'LawPublishDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/admin/LawPublishDetail.vue')
      },
      // 基本情况
      {
        path: '/CommunityIntroduction',
        name: 'CommunityIntroduction',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/CommunityIntroduction.vue')
      },
      {
        path: '/CommunityMember',
        name: 'CommunityMember',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/CommunityMember.vue')
      },
      {
        path: '/PropertyMember',
        name: 'PropertyMember',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/PropertyMember.vue')
      },
      {
        path: '/RepairFrom',
        name: 'RepairFrom',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/RepairFrom.vue')
      },
      {
        path: '/HealthMember',
        name: 'HealthMember',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/HealthMember.vue')
      },
      {
        path: '/MediationMember',
        name: 'MediationMember',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/MediationMember.vue')
      },
      {
        path: '/SuggestEmail',
        name: 'SuggestEmail',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/SuggestEmail.vue')
      },
      {
        path: '/RepairList',
        name: 'RepairList',
        component: () => import(/* webpackChunkName: "about" */ '../views/menu/basic/RepairList.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
