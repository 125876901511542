<template>
  <router-view />
</template>

<style lang="less">
#app {
  width: 100%;
  height: 100%;

  // a {
  //   font-size: 20px;
  //   font-family: Source Han Sans CN;
  //   font-weight: 400;
  //   color: #FAE19C;
  //   text-decoration: none;
  // }
}

html,
body {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
<style lang="less">
/* 滚动条 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* 滚动条里面小方块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #53535347;
}
</style>
