import { http } from "./index";

// -----------------首页
// 获取地图楼栋数据
export const getBuildingList = (data: object) => {
  return http.request("post", `/index/home/building_list`, { data });
};
// 获取天气
export const getWeatherInfo = (data: object) => {
  return http.request("post", `/index/home/weather_info`, { data });
};
// 第一议题
export const getFirstTopicList = (data: object) => {
  return http.request("post", `/index/home/first_topic_list`, { data });
};
// 社区发布
export const getCommunityPublishList = (data: object) => {
  return http.request("post", `/index/home/community_publish_list`, { data });
};
// 网格宣传
export const getGridPublishList = (data: object) => {
  return http.request("post", `/index/home/grid_publish_list`, { data });
};
// 社区工作人员
export const getCommunityMemberList = (data: object) => {
  return http.request("post", `/index/home/community_member_list`, { data });
};
// 优秀党员
export const getPartyPioneerList = (data: object) => {
  return http.request("post", `/index/home/party_pioneer_list`, { data });
};
// 积分排行榜
export const getIntegralList = (data: object) => {
  return http.request("post", `/index/home/integral_list`, { data });
};

// ---------智慧党建
// 支部介绍
export const getBranchInfo = (data: object) => {
  return http.request("post", `/index/party/branch_info`, { data });
};
// 第一议题
export const getPartyFirstTopicList = (data: object) => {
  return http.request("post", `/index/party/first_topic_list`, { data });
};
// 第一议题详情
export const getPartyFirstTopicDetail = (data: object) => {
  return http.request("post", `/index/party/first_topic_detail`, { data });
};
// 党风廉政
export const getIntegrityList = (data: object) => {
  return http.request("post", `/index/party/integrity_list`, { data });
};
// 第一议题详情
export const getIntegrityDetail = (data: object) => {
  return http.request("post", `/index/party/integrity_detail`, { data });
};
// 组织先锋展规则
export const getOrganizePioneerRule = (data: object) => {
  return http.request("post", `/index/party/organize_pioneer`, { data });
};
// 组织先锋展列表
export const getOrganizePioneerList = (data: object) => {
  return http.request("post", `/index/party/organize_pioneer_list`, { data });
};
// 组织先锋展详情
export const getOrganizePioneerDetail = (data: object) => {
  return http.request("post", `/index/party/organize_pioneer_detail`, { data });
};
// 党员先锋展规则
export const getPartyPioneerRule = (data: object) => {
  return http.request("post", `/index/party/party_pioneer`, { data });
};
// 党员先锋展列表
export const getPartyPioneerList2 = (data: object) => {
  return http.request("post", `/index/party/party_pioneer_list`, { data });
};
// 党员先锋展详情
export const getPartyPioneerDetail = (data: object) => {
  return http.request("post", `/index/party/party_pioneer_detail`, { data });
};
// 党建信息发布
export const getPartyPublishList = (data: object) => {
  return http.request("post", `/index/party/party_publish_list`, { data });
};
// 党建信息发布
export const getPartyPublishDetail = (data: object) => {
  return http.request("post", `/index/party/party_publish_detail`, { data });
};
// 建议信箱
export const getSuggestEmail = (data: object) => {
  return http.request("post", `/index/party/suggest_email`, { data });
};
// ---------------新时代
// 志愿者管理
export const getVolunteerInfo = (data: object) => {
  return http.request("post", `/index/newera/volunteer_info`, { data });
};
// 文体活动管理
export const getSportsActivitiesInfo = (data: object) => {
  return http.request("post", `/index/newera/sports_activities_info`, { data });
};
// 服务项目管理
export const getServiceItemsInfo = (data: object) => {
  return http.request("post", `/index/newera/service_items_info`, { data });
};
// 社区服务队伍
export const getCommunityServiceInfo = (data: object) => {
  return http.request("post", `/index/newera/community_service_info`, { data });
};
// 电子阅读列表
export const getDigitalReadingList = (data: object) => {
  return http.request("post", `/index/newera/digital_reading_list`, { data });
};
// 电子阅读详情
export const getDigitalReadingDetail = (data: object) => {
  return http.request("post", `/index/newera/digital_reading_detail`, { data });
};
// 社区宣传公示列表
export const getNeweraCommunityPublishList = (data: object) => {
  return http.request("post", `/index/newera/community_publish_list`, { data });
};
// 社区宣传公示详情
export const getNeweraCommunityPublishDetail = (data: object) => {
  return http.request("post", `/index/newera/community_publish_detail`, { data });
};
// 志愿活动公示列表
export const getVolunteerActiveList = (data: object) => {
  return http.request("post", `/index/newera/volunteer_active_list`, { data });
};
// 社区宣传公示详情
export const getVolunteerActiveDetail = (data: object) => {
  return http.request("post", `/index/newera/volunteer_active_detail`, { data });
};
// -------------农业农村
// 最低生活保障
export const getLifeInsuranceInfo = (data: object) => {
  return http.request("post", `/index/village/life_insurance_info`, { data });
};
// 残疾管理
export const getDisabledInfo = (data: object) => {
  return http.request("post", `/index/village/disabled_info`, { data });
};
// 尊老金管理
export const getOldageGoldInfo = (data: object) => {
  return http.request("post", `/index/village/oldage_gold_info`, { data });
};
// 困境儿童管理
export const getTroubledChildrenInfo = (data: object) => {
  return http.request("post", `/index/village/troubled_children_info`, { data });
};
// 五保户管理
export const getFiveGuaranteesInfo = (data: object) => {
  return http.request("post", `/index/village/five_guarantees_info`, { data });
};
// 殡葬补助
export const getFuneralAllowanceInfo = (data: object) => {
  return http.request("post", `/index/village/funeral_allowance_info`, { data });
};
// 60基础养老金
export const getBasicPensionInfo = (data: object) => {
  return http.request("post", `/index/village/basic_pension_info`, { data });
};
// 死亡注销
export const getDeathLogoffInfo = (data: object) => {
  return http.request("post", `/index/village/death_logoff_info`, { data });
};
// 社保一次性结清
export const getClearAccountInfo = (data: object) => {
  return http.request("post", `/index/village/clear_account_info`, { data });
};
// 照护保险
export const getNursingInsuranceInfo = (data: object) => {
  return http.request("post", `/index/village/nursing_insurance_info`, { data });
};
// 积分管理规则
export const getVillageIntegralRule = (data: object) => {
  return http.request("post", `/index/village/integral_info`, { data });
};
// 积分管理规则列表
export const getVillageIntegralList = (data: object) => {
  return http.request("post", `/index/village/integral_list`, { data });
};
// 积分管理规则下拉
export const getSelectList = (data: object) => {
  return http.request("post", `/index/village/select_list`, { data });
};

// --------------综合治理
// 网格信息
export const getGridInfo = (data: object) => {
  return http.request("post", `/index/admin/grid_info`, { data });
};
// 治安保卫
export const getSecurityInfo = (data: object) => {
  return http.request("post", `/index/admin/security_info`, { data });
};
// 人民调解
export const getMediationInfo = (data: object) => {
  return http.request("post", `/index/admin/mediation_info`, { data });
};
// 网格宣传列表
export const getAdminGridPublishList = (data: object) => {
  return http.request("post", `/index/admin/grid_publish_list`, { data });
};
// 网格宣传详情
export const getAdminGridPublishDetail = (data: object) => {
  return http.request("post", `/index/admin/grid_publish_detail`, { data });
};
// 法制宣传列表
export const getLawPublishList = (data: object) => {
  return http.request("post", `/index/admin/law_publish_list`, { data });
};
// 法制宣传详情
export const getLawPublishDetail = (data: object) => {
  return http.request("post", `/index/admin/law_publish_detail`, { data });
};
// ------------------基本情况
// 社区介绍
export const getCommunityIntroductionInfo = (data: object) => {
  return http.request("post", `/index/basic/community_introduction_info`, { data });
};
// 社区工作人员
export const getBasicCommunityMemberList = (data: object) => {
  return http.request("post", `/index/basic/community_member_list`, { data });
};
// 物业工作人员
export const getBasicPropertyMemberList = (data: object) => {
  return http.request("post", `/index/basic/property_member_list`, { data });
};
// 物业工作人员报修
export const saveRepair = (data: object) => {
  return http.request("post", `/index/basic/save_repair`, { data });
};
// 卫生室工作人员
export const getBasicHealthMemberList = (data: object) => {
  return http.request("post", `/index/basic/health_member_list`, { data });
};
// 调解委员会工作人员
export const getBasicMediationMemberList = (data: object) => {
  return http.request("post", `/index/basic/mediation_member_list`, { data });
};
// 报修列表
export const getRepairList = (data: object) => {
  return http.request("post", `/index/basic/repair_list`, { data });
};
